import React from 'react';
import _ from 'lodash';
import { formatDate } from 'react-day-picker/moment';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Label from '../components/Label';
import Select from '../components/Select';
import Small from '../components/Small';
import Box from '../components/Box';
import DayPicker from '../components/DayPicker';
import TimeInput from '../components/TimeInput';

const Fieldset = ({
  component = 'input',
  render,
  name,
  label,
  labelProps = {},
  inlineBlock,
  fieldProps = {},
  ...rest
}) => (
  <Field
    name={name}
    render={({ field, form }) => {
      const error = form.touched[name] && form.errors[name];
      const hasError = !!error;

      let element = null;
      if (render) {
        element = render({ field, form, ...rest }); // render prop inception
      } else if (component) {
        let restProps = rest;
        if (_.includes([TimeInput.displayName, Select.displayName], component.displayName)) {
          // @NOTE
          // react-select does not use the React synthetic events, we
          // must manually call setFieldValue/setFieldTouched
          // Ref: https://github.com/jaredpalmer/formik/issues/187
          restProps = {
            ...rest,
            onChange: (value) => {
              if (rest.onChange) {
                rest.onChange(value);
              }
              form.setFieldValue(name, value);
            },
            onBlur: () => {
              if (rest.onBlur) {
                rest.onBlur();
              }
              form.setFieldTouched(name, true);
            },
          };
        } else if (component.displayName === DayPicker.displayName) {
          restProps = {
            ...rest,
            onDayChange: (date, modifiers) => {
              if (!date) return;
              if (rest.onDayChange) {
                rest.onDayChange(date, modifiers);
              }
              form.setFieldValue(name, formatDate(date));
            },
            inputProps: {
              ...(rest.inputProps ? rest.inputProps : {}),
              onChange: (event) => {
                if (rest.inputProps && rest.inputProps.onChange) {
                  rest.onChange(event);
                }
                form.setFieldValue(name, event.target.value);
              },
              // onBlur was being triggered directly after day selected,
              // wait a moment in case the value was selected so we don't
              // display an error.
              onBlur: _.debounce(() => {
                if (rest.onBlur) {
                  rest.onBlur();
                }
                form.setFieldTouched(name, true);
              }, 100),
            },
          };
        }
        element = React.createElement(component, {
          ...field,
          ...restProps,
          hasError,
        });
      }

      return (
        <div
          {...fieldProps}
          style={{
            ...(inlineBlock ? { display: 'inline-block' } : {}),
            ...(fieldProps.style || {}),
          }}
        >
          <Box paddingBottom={error ? 'sm' : 'md'}>
            <Box paddingBottom="xs">
              <Label htmlFor={name} hasError={hasError} {...labelProps}>
                {label}
              </Label>
            </Box>
            {element}
          </Box>
          {error && (
            <Box paddingBottom="md">
              <Small hasError>{error}</Small>
            </Box>
          )}
        </div>
      );
    }}
  />
);

Fieldset.propTypes = {
  component: PropTypes.any,
  render: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.any,
};

export default Fieldset;
