/**
 * Helper functions for validating a form using spected
 * @private
 */
import spected from 'spected';

export function isEmpty(value) {
  return (typeof value === 'string' && value.length === 0) || value === undefined;
}
export function isNotEmpty(value) {
  return !isEmpty(value);
}

export function isEmail(value) {
  const re = /^([\w.\-_+]+)?\w+@[\w-_]+(\.\w+){1,}$/;
  return re.test(value);
}

export function isLatitude(value) {
  const re = /^-?\d+(\.\d{1,6})?$/;
  return re.test(value) && value <= 90 && value >= -90;
}

export function isLongitude(value) {
  const re = /^-?\d+(\.\d{1,6})?$/;
  return re.test(value) && value <= 180 && value >= -180;
}

export function isPhoneNumber(value) {
  const re = /^\+[0-9]{10,15}$/;
  return re.test(value);
}
export function isPassword(value) {
  const re = /^.{8,}$/;
  return re.test(value);
}

export function isDate(value) {
  const re = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/20\d\d$/;
  return re.test(value);
}

export function isMilitaryTime(value) {
  const re = /^([01][0-9]|2[0123]):[0-5][0-9]$/;
  return re.test(value);
}

export function isWholeNumber(value) {
  const re = /^\d+$/;
  return re.test(value);
}

export function isDecimalNumber(value) {
  const re = /^\d+(.\d+)?$/;
  return re.test(value);
}

export function isTrue(value) {
  return value === true;
}

// Spected helper function for defining the validation schema
export const validate = (getValidationSchema) => (values) => {
  const spec = getValidationSchema(values);
  const validationResult = spected(spec, values);
  return getErrorsFromValidationResult(validationResult);
};

function getErrorsFromValidationResult(validationResult) {
  const FIRST_ERROR = 0;
  return Object.keys(validationResult).reduce(
    (errors, field) =>
      validationResult[field] !== true
        ? { ...errors, [field]: validationResult[field][FIRST_ERROR] }
        : errors,
    {}
  );
}

// Generates a validator which can be used on ant.d FormItems
export function createFormItemValidator(validator, message, { isRequired } = { isRequired: true }) {
  return {
    validator: (rule, value, callback) => {
      if (!isRequired && value === '') {
        callback();
        return;
      }
      if (!validator(value)) {
        callback(message);
      } else {
        callback();
      }
    },
  };
}
