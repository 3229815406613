import styled from 'styled-components';
import React from 'react';

const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: ${(props) => props.theme.borderRadius};
    border-color: ${(props) => props.theme.uiColor.outline};
    border-width: 1px;
    border-style: solid;
    box-sizing: content-box;
  }

  // Box checked
  &:checked + label:before {
    background: ${(props) => props.theme.fontColor.link};
    border-color: ${(props) => props.theme.fontColor.link};
    box-sizing: content-box;
  }

  // Disabled state label.
  &:disabled + label {
    cursor: auto;
    opacity: 0.6;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: ${(props) => props.theme.uiColor.outline};
    opacity: 0.6;
    box-sizing: content-box;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    box-sizing: content-box;
  }
`;

const Label = styled.label`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.inputLabel.fontSize};
  letter-spacing: 0.1px;
  color: ${(props) =>
    props.disabled ? props.theme.uiColor.outline : props.theme.fontColor.primary};
`;

let count = 0;
export default ({ checked, name, disabled, value, onChange, contents, children }) => (
  <div>
    <CheckboxInput
      name={name}
      checked={checked}
      value={value}
      disabled={disabled}
      onChange={onChange}
      id={name || `airbusutm-ui-checkbox-${count}`}
      data-ui={name || `airbusutm-ui-checkbox-${count}`}
    />
    <Label disabled={disabled} htmlFor={name || `airbusutm-ui-checkbox-${count++}`}>
      {children || contents}
    </Label>
  </div>
);
