import styled from 'styled-components';

export default styled.small`
  color: ${(props) =>
    props.hasError ? props.theme.fontColor.error : props.theme.fontColor.primary};
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.small.fontSize};
  line-height: 21px;
  display: ${(props) => (props.block ? 'block' : 'inline')};
`;
