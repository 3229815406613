import React from 'react';
import styled from 'styled-components';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import { Grid as FlexboxGrid, Row as FlexboxRow, Col as FlexboxCol } from 'react-flexbox-grid';
import { withTheme } from 'styled-components';
import { convertPadding, withResponsivePadding } from './Padding';
import { HBar } from './index';

export const Grid = withResponsivePadding(
  withTheme(
    ({
      fullWidth,
      style,
      padding,
      vpadding,
      hpadding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      theme,
      ...props
    }) => (
      <FlexboxGrid
        style={{
          padding: 0,
          margin: 'auto',
          ...(fullWidth ? { width: '100%' } : {}),
          ...convertPadding(
            theme.padding,
            {
              padding,
              vpadding,
              hpadding,
              paddingTop,
              paddingRight,
              paddingBottom,
              paddingLeft,
            },
            style
          ),
          ...style,
        }}
        {...props}
      />
    )
  )
);

export const Row = withResponsivePadding(
  withTheme(
    ({
      padding,
      vpadding,
      hpadding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      style,
      theme,
      ...rest
    }) => {
      const stylePadding = convertPadding(
        theme.padding,
        {
          padding,
          vpadding,
          hpadding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
        },
        style
      );

      return (
        <FlexboxRow
          style={{
            ...stylePadding,
            margin: 'auto',
            ...style,
          }}
          {...rest}
        />
      );
    }
  )
);

export const Col = withResponsivePadding(
  withTheme(
    ({
      padding,
      vpadding,
      hpadding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      style,
      theme,
      ...rest
    }) => {
      const stylePadding = convertPadding(
        theme.padding,
        {
          padding,
          vpadding,
          hpadding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
        },
        style
      );

      return (
        <FlexboxCol
          style={{
            ...stylePadding,
            ...style,
          }}
          {...rest}
        />
      );
    }
  )
);

export const RowBar = ({ md, style, ...props }) => (
  <Row {...props}>
    <Col style={{ paddingLeft: 0, paddingRight: 0 }} md={md || 12}>
      <HBar style={{ width: '100%' }} />
    </Col>
  </Row>
);
