import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { calcPadding, withResponsivePadding } from './Padding';

export default withResponsivePadding(
  withTheme(styled.div`
    display: ${(props) => (props.inline ? 'inline' : 'block')};
    padding-top: ${(props) => {
      if (!props.paddingTop && !props.vpadding && !props.padding) return '0px';
      return calcPadding(props.theme.padding, props.paddingTop || props.vpadding || props.padding);
    }};
    padding-bottom: ${(props) => {
      if (!props.paddingBottom && !props.vpadding && !props.padding) return '0px';
      return calcPadding(
        props.theme.padding,
        props.paddingBottom || props.vpadding || props.padding
      );
    }};
    padding-right: ${(props) => {
      if (!props.paddingRight && !props.hpadding && !props.padding) return '0px';
      return calcPadding(
        props.theme.padding,
        props.paddingRight || props.hpadding || props.padding
      );
    }};
    padding-left: ${(props) => {
      if (!props.paddingLeft && !props.hpadding && !props.padding) return '0px';
      return calcPadding(props.theme.padding, props.paddingLeft || props.hpadding || props.padding);
    }};
  `)
);
