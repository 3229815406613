(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("react-dom"), require("formik"));
	else if(typeof define === 'function' && define.amd)
		define("AirbusUtmUi", ["react", "styled-components", "react-dom", "formik"], factory);
	else if(typeof exports === 'object')
		exports["AirbusUtmUi"] = factory(require("react"), require("styled-components"), require("react-dom"), require("formik"));
	else
		root["AirbusUtmUi"] = factory(root["React"], root["styled"], root["ReactDOM"], root["formik"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__88__) {
return 