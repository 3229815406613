import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import { hexToRgbA } from './helpers';

const componentToString = (component) =>
  encodeURIComponent(ReactDOMServer.renderToStaticMarkup(component));

export default styled.input`
  color: ${(props) =>
    props.disabled ? props.theme.fontColor.secondary : props.theme.fontColor.primary};
  background-color: ${(props) =>
    props.disabled ? hexToRgbA(props.theme.uiColor.outline, 0.2) : props.theme.uiColor.neutral};
  border-width: ${(props) => (props.disabled ? '0px' : '1px')};
  border-style: ${(props) => (props.type === 'file' ? 'none' : 'solid')};
  border-color: ${(props) =>
    props.hasError ? props.theme.uiColor.error : props.theme.uiColor.outline};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => (props.type === 'file' ? '10px 12px 10px 0px' : '10px 12px')};
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.input.fontSize};
  font-weight: normal;
  background-image: ${(props) =>
    props.icon ? `url('data:image/svg+xml;utf8,${componentToString(props.icon)}')` : null};
  background-position-x: ${(props) => (props.icon ? '12px' : null)};
  background-position-y: ${(props) => (props.icon ? '9px' : null)};
  background-repeat: no-repeat;
  text-indent: ${(props) => (props.icon ? '33px' : null)};
  /* Over-compensate when input is disabled since alpha border color does not work correctly */
  line-height: ${(props) => (props.disabled ? '26px' : '24px')};
  width: 100%;
  letter-spacing: 0.1px;
  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.fontColor.hint};
  }
  &:focus {
    color: ${(props) => props.theme.fontColor.primary};
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.hasError ? props.theme.uiColor.error : props.theme.uiColor.secondary};
  }
`;
