import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export const withMediaQuery = (WrappedComponent) => (props) => {
  const mediaQuery = {
    isMobile: useMediaQuery({ maxWidth: 767 }),
    isTablet: useMediaQuery({ minWidth: 768, maxWidth: 1199 }),
    isXs: useMediaQuery({ maxWidth: 575 }),
    isSm: useMediaQuery({ minWidth: 576, maxWidth: 767 }),
    isMd: useMediaQuery({ minWidth: 768, maxWidth: 991 }),
    isLg: useMediaQuery({ minWidth: 992, maxWidth: 1199 }),
    isXl: useMediaQuery({ minWidth: 1200 }),
    isPortrait: useMediaQuery({ orientation: 'portrait' }),
  };
  return <WrappedComponent {...props} mediaQuery={mediaQuery} />;
};

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1200 });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1199 });
  return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export const Portrait = ({ children }) => {
  const isPortrait = useMediaQuery({ orientation: 'portrait' });
  return isPortrait ? children : null;
};

export const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
