import axios from 'axios';
import camelcaseKeysDeep from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';

/**
 * Wrapper around axios
 * Includes interceptors to (de)camelize
 */
export function createClient(options = {}) {
  const api = axios.create(options);
  // Convert response from_this toThis
  if (options.camelize || options.camelcaseResponse) {
    api.interceptors.response.use(
      (response) => {
        if (response.data) {
          response.data = camelcaseKeysDeep(response.data);
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.data) {
          error.response.data = camelcaseKeysDeep(error.response.data);
        }
        return Promise.reject(error);
      }
    );
  }

  // Convert request fromThis to_this
  if (options.camelize || options.snakecaseRequest) {
    api.interceptors.request.use((request) => {
      if (request.params) {
        request.params = decamelizeKeysDeep(request.params);
      }
      if (request.data) {
        request.data = decamelizeKeysDeep(request.data);
      }
      return request;
    });
  }
  return api;
}
