import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Input } from './index';

const DayPickerWrapper = (props) => (
  <DayPickerInput
    classNames={{
      ...DayPickerInput.defaultProps.classNames,
      // disable default class which sets it to inline-block
      container: '',
    }}
    component={Input}
    formatDate={formatDate}
    parseDate={parseDate}
    keepFocus={false}
    placeholder={`${formatDate(new Date())}`}
    {...props}
  />
);

DayPickerWrapper.displayName = 'DayPickerWrapper';

export default DayPickerWrapper;
