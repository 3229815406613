import styled from 'styled-components';
import { hexToRgbA } from './helpers';
import * as Icons from './Icons';
export { default as Box } from './Box';
export { default as Button } from './Button';
export { default as ButtonIcon } from './ButtonIcon';
export { default as Checkbox } from './Checkbox';
export { default as DayPicker } from './DayPicker';
export { default as Label } from './Label';
export { default as Modal } from './Modal';
export { Radio, RadioGroup } from './Radio';
export { default as Select } from './Select';
export { default as Small } from './Small';
export { Col, Grid, Row, RowBar } from './Grid';
export { default as TimeInput } from './TimeInput';
export { default as Loader } from './Loader';
export { default as ThemeProvider, defaultTheme } from './ThemeProvider';
export { ErrorText, Text } from './Text';
export { default as Input } from './Input';
export { default as Printable } from './Printable';
export { default as UploadButton } from './UploadButton';
export { default as Spinner } from './Spinner';
export { withMediaQuery, Default, Mobile, Tablet, Desktop } from './MediaQuery';
export { default as Menu, MenuHeader, MenuItem, MenuCheckbox } from './Menu';

export { hexToRgbA, Icons };

export const Card = styled.div`
  border-color: ${(props) => hexToRgbA(props.theme.uiColor.outline, 0.5)};
  border-radius: ${(props) => props.theme.borderRadius};
  border-style: solid;
  border-width: 1px;
  box-shadow: ${(props) => (props.boxShadow ? props.theme.boxShadow : 'none')};
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.uiColor.neutral};
  font-family: ${(props) => props.theme.fontFamily.default}
  &::after {
    content: " ";
    display: ${(props) => (props.tooltip ? 'block' : 'none')};
    position: absolute;
    top: 100%;
    left: 50%;
    transform: rotate(45deg);
    margin-left: -11px;
    border-width: 11px;
    border-style: solid;
    border-color: ${({ theme }) => `${theme.uiColor.neutral} ${theme.uiColor.neutral} transparent`};
    box-shadow: ${(props) => (props.boxShadow ? '3px 3px 6px  rgba(0, 0, 0, 0.12)' : 'none')};
    margin-top: -11px;
  }
  @media (max-width: 575px) {
    padding: 32px 24px;
  }
  @media (max-width: 767px) {
    padding: 24px 24px;
  }
  @media (max-width: 991px) {
    padding: 24px 16px;
  }
`;

export const Notification = styled.div`
  color: ${(props) => props.theme.uiColor.neutral};
  background-color: ${(props) =>
    props.type === 'error' ? props.theme.uiColor.error : props.theme.uiColor.secondary};
  border-color: ${(props) => hexToRgbA(props.theme.uiColor.outline, 0.5)};
  border-radius: ${(props) => props.theme.borderRadius};
  border-style: solid;
  border-width: 1px;
  letter-spacing: 0.1px;
  padding: 10px;
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.textarea};
  text-align: center;
`;

export const Textarea = styled.textarea`
  color: ${(props) =>
    props.disabled ? props.theme.fontColor.hint : props.theme.fontColor.primary};
  background-color: ${(props) =>
    props.disabled ? hexToRgbA(props.theme.uiColor.outline, 0.2) : props.theme.uiColor.neutral};
  border-width: ${(props) => (props.disabled ? '0px' : '1px')};
  border-style: solid;
  border-color: ${(props) =>
    props.hasError ? props.theme.uiColor.error : props.theme.uiColor.outline};
  border-radius: ${(props) => props.theme.borderRadius};
  box-sizing: border-box;
  padding: 10px 12px;
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.textarea.fontSize};
  font-weight: normal;
  /* Over-compensate when input is disabled since alpha border color does not work correctly */
  line-height: ${(props) => (props.disabled ? '26px' : '24px')};
  width: 100%;
  letter-spacing: 0.1px;
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.fontColor.hint};
  }
  &:focus {
    color: ${({ theme }) => theme.fontColor.primary};
    outline: none;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) =>
      props.hasError ? props.theme.uiColor.error : props.theme.uiColor.secondary};
  }
`;

export const PageTitle = styled.h1`
  color: ${({ theme }) => theme.fontColor.primary};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.pageTitle.fontSize};
  font-weight: 500;
  letter-spacing: -0.43px;
  line-height: 49px;
  margin: auto;
`;

export const SectionHeader = styled.h2`
  color: ${({ theme }) => theme.fontColor.primary};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.sectionHeader.fontSize};
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 29px;
  margin: auto;
`;

export const BoldText = styled.h3`
  color: ${({ theme }) => theme.fontColor.primary};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.boldText.fontSize};
  font-weight: 500;
  line-height: 40px;
  margin: auto;
`;

export const BigNumber = styled.h2`
  color: ${({ theme }) => theme.fontColor.primary};
  font-family: ${({ theme }) => theme.fontFamily.mono};
  font-size: ${({ theme }) => theme.bigNumber.fontSize};
  font-weight: normal;
  letter-spacing: 0.1px;
  line-height: 29px;
`;

export const HBar = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 0px;
  box-sizing: border-box;
  height: 1px;
  width: 573px;
`;
