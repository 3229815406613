import React from 'react';
import Modal from 'react-modal';
import { defaultTheme } from './ThemeProvider';

export const defaultStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255, 255, 255, 0)',
    padding: 0,
    border: 'unset',
    overflow: 'unset',
    overscrollBehavior: 'contain',
    // Will fit to viewpport and scroll if needed
    maxHeight: '100vh',
    maxWidth: '100vw',
    overflowY: 'auto',
  },
  overlay: {
    ...Modal.defaultStyles.overlay,
    zIndex: 15,
  },
};

export default (props) => <Modal ariaHideApp={false} style={defaultStyles} {...props} />;
