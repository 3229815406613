import React from 'react';
import { withMediaQuery } from './MediaQuery';

export const calcPadding = (paddingStyles, paddingType) => paddingStyles[paddingType] || '0px';

export const convertPadding = (
  paddingStyles,
  { padding, hpadding, vpadding, paddingLeft, paddingRight, paddingTop, paddingBottom },
  style = {}
) => {
  let top;
  let right;
  let bottom;
  let left;

  if (typeof style.padding === 'string' && /^\d+px\s+\d+px\s+\d+px\s+\d+px$/.test(style.padding)) {
    [top, right, bottom, left] = style.padding.split(/\s+/);
  }

  if (padding) {
    top = style.paddingTop !== undefined ? style.paddingTop : calcPadding(paddingStyles, padding);
    right =
      style.paddingRight !== undefined ? style.paddingRight : calcPadding(paddingStyles, padding);
    bottom =
      style.paddingBottom !== undefined ? style.paddingBottom : calcPadding(paddingStyles, padding);
    left =
      style.paddingLeft !== undefined ? style.paddingLeft : calcPadding(paddingStyles, padding);
  }

  if (vpadding) {
    top = style.paddingTop !== undefined ? style.paddingTop : calcPadding(paddingStyles, vpadding);
    bottom =
      style.paddingBottom !== undefined
        ? style.paddingBottom
        : calcPadding(paddingStyles, vpadding);
  }

  if (hpadding) {
    left =
      style.paddingLeft !== undefined ? style.paddingLeft : calcPadding(paddingStyles, hpadding);
    right =
      style.paddingRight !== undefined ? style.paddingRight : calcPadding(paddingStyles, hpadding);
  }

  if (paddingTop) {
    top =
      style.paddingTop !== undefined ? style.paddingTop : calcPadding(paddingStyles, paddingTop);
  }

  if (paddingRight) {
    right =
      style.paddingRight !== undefined
        ? style.paddingRight
        : calcPadding(paddingStyles, paddingRight);
  }

  if (paddingBottom) {
    bottom =
      style.paddingBottom !== undefined
        ? style.paddingBottom
        : calcPadding(paddingStyles, paddingBottom);
  }

  if (paddingLeft) {
    left =
      style.paddingLeft !== undefined ? style.paddingLeft : calcPadding(paddingStyles, paddingLeft);
  }

  return {
    paddingTop: top || 0,
    paddingRight: right || 0,
    paddingBottom: bottom || 0,
    paddingLeft: left || 0,
  };
};

export const withResponsivePadding = (WrappedComponent) =>
  withMediaQuery(({ mediaQuery: { isXs, isSm, isMd, isLg, isXl }, ...props }) => {
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
    const padProps = [
      'paddingTop',
      'paddingRight',
      'paddingBottom',
      'paddingLeft',
      'hpadding',
      'vpadding',
      'padding',
    ];
    const overrideProps = {};
    sizes.forEach((size) => {
      padProps.forEach((padProp) => {
        let propName = `${padProp.slice(0, 1).toUpperCase()}${padProp.slice(1)}`;
        propName = `${size}${propName}`;
        if (props[propName]) {
          if (
            (isXs && size === 'xs') ||
            (isSm && size === 'sm') ||
            (isMd && size === 'md') ||
            (isLg && size === 'lg') ||
            (isXl && size === 'xl')
          ) {
            // e.g., newProps['paddingLeft']  = props['xsPaddingLeft']
            overrideProps[padProp] = props[propName];
          }
          delete props[propName];
        }
      });
    });
    return <WrappedComponent {...{ ...props, ...overrideProps }} />;
  });
