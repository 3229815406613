import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  position: absolute; // hide it
  opacity: 0;
  & + label {
    position: initial;
    display: inline-block;
    margin: 0;
    text-indent: 1.5em;
    min-width: calc(1em + 2px);
    cursor: pointer;
  }
  & + label:before {
    content: '';
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    display: block;
    box-sizing: border-box;
    border-radius: 100%;
    border: ${(props) => `1px solid ${props.theme.uiColor.outline}`};
    z-index: 0;
    position: absolute;
    left: 0;
  }
  &:checked + label:before {
    border: ${(props) => `1px solid ${props.theme.fontColor.link}`};
  }
  &:checked + label:after {
    transform: scale(0.5);
    background-color: ${(props) => props.theme.fontColor.link};
    content: '';
    width: calc(1em + 2px);
    height: calc(1em + 2px);
    display: block;
    box-sizing: border-box;
    border-radius: 100%;
    z-index: 0;
    position: absolute;
    left: 0;
    top: 1px;
  }
`;

const Label = styled.label`
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.inputLabel.fontSize};
  color: ${(props) =>
    props.disabled ? props.theme.uiColor.outline : props.theme.fontColor.primary};
  cursor: pointer;
`;

const Radio = ({ name, checked, disabled, value, onChange, children }) => (
  <div
    style={{
      position: 'relative',
      display: 'inline-block',
      lineHeight: 1.2,
      fontSize: 'initial',
    }}
  >
    <RadioInput
      type="radio"
      name={name}
      checked={checked}
      value={value}
      disabled={disabled}
      onChange={onChange}
      id={`airbusutm-tools-radio-${name}-${value}`}
    />
    <Label disabled={disabled} htmlFor={`airbusutm-tools-radio-${name}-${value}`}>
      {children}
    </Label>
  </div>
);

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onChange: PropTypes.func,
};

const RadioGroup = class extends React.Component {
  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleBlur = (event) => {
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  render() {
    const { options, name, direction, value: checkedValue } = this.props;
    const isRow = direction === 'row';
    const offsetPadding = isRow ? { paddingLeft: 16 } : { paddingTop: 20 };
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: isRow ? 'row' : 'column',
        }}
      >
        {options.map(({ value, label }, index) => (
          <div style={{ ...(index > 0 ? offsetPadding : {}) }} key={value}>
            <Radio
              name={name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              value={value}
              checked={String(value) === String(checkedValue)}
            >
              {label}
            </Radio>
          </div>
        ))}
      </div>
    );
  }
};

RadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  direction: PropTypes.oneOf(['column', 'row']),
};

export { RadioGroup, Radio };
