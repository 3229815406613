import React from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';

export const defaultTheme = {
  fontFamily: {
    default: 'Heebo, Arial',
    mono: '"Anonymous Pro", Courier',
  },
  fontColor: {
    primary: '#2E3E48',
    secondary: '#7D8589',
    hint: '#DDE3E8',
    error: '#DE8C3E',
    failure: '#DE503D',
    link: '#5682E4',
    inverted: '#FFFFFF',
  },
  uiColor: {
    primary: '#2E6EC2', // ocean
    secondary: '#5682E4', // sky blue
    highlight: '#449292', // moss
    success: '#076B6B', // forest
    outline: '#CCD2D7', // slate
    neutral: '#FFFFFF', // cloud white
    error: '#DE8C3E',
  },
  boxShadow: '0px 2px 16px 0 rgba(0, 0, 0, 0.12)',
  borderRadiusTop: '4px 4px 0px 0px', // ? ? BORDER_RADIUS_TOP
  borderRadius: '4px',
  button: {
    fontSize: '14px',
  },
  label: {
    fontSize: '12px',
  },
  // For checkbox or radio
  inputLabel: {
    fontSize: '16px',
  },
  small: {
    fontSize: '14px',
  },
  text: {
    fontSize: '16px',
  },
  input: {
    fontSize: '16px',
  },
  textarea: {
    fontSize: '16px',
  },
  pageTitle: {
    fontSize: '36px',
  },
  sectionHeader: {
    fontSize: '24px',
  },
  boldText: {
    fontSize: '18px',
  },
  bigNumber: {
    fontSize: '24px',
  },
  padding: {
    none: '0px',
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    xxxl: '64px',
  },
};

export default ({ theme, ...rest }) => <ThemeProvider theme={theme || defaultTheme} {...rest} />;
