import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UploadButton extends Component {
  static propTypes = {
    isUploading: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.input = null;
  }

  onChange = (event) => {
    this.props.onChange(event.target.files);
    if (this.input) {
      this.input.value = '';
    }
  };

  render() {
    const { children, isUploading } = this.props;

    return (
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          display: 'inline-block',
          cursor: 'pointer',
        }}
      >
        {children({ isUploading })}
        <input
          ref={(node) => {
            this.input = node;
          }}
          type="file"
          name="myfile"
          style={{
            fontSize: 100,
            position: 'absolute',
            left: 0,
            top: 0,
            opacity: 0,
            zIndex: 2,
          }}
          onChange={this.onChange}
          disabled={isUploading}
        />
      </div>
    );
  }
}

export default UploadButton;
