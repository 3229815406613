import React from 'react';
import ReactTooltip from 'react-tooltip';
import { withTheme } from 'styled-components';
import Small from './Small';
import Button from './Button';
import { withMediaQuery } from './MediaQuery';

const ButtonIcon = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color ? this.props.color : this.props.theme.fontColor.primary,
    };
  }

  handleMouseEnter = () => this.setState({ color: this.props.theme.fontColor.link });

  handleMouseLeave = () =>
    this.setState({
      color: this.props.color ? this.props.color : this.props.theme.fontColor.primary,
    });

  render() {
    const { icon: Icon, faIcon, id, tooltip, place, mediaQuery, ...restProps } = this.props;
    const { isMobile } = mediaQuery;
    const { color } = this.state;
    const FAIcon = faIcon || undefined;
    return (
      <span>
        <a data-tip data-for={`tooltip-icon-${id}`}>
          <Button
            link
            type="button"
            style={{
              outlineStyle: 'none',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              padding: 0,
            }}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            {...restProps}
          >
            {FAIcon ? <FAIcon style={{ color, fontSize: 14 }} /> : <Icon color={color} />}
          </Button>
        </a>
        {!isMobile && tooltip && (
          <ReactTooltip id={`tooltip-icon-${id}`} effect="solid" place={place || 'bottom'}>
            <Small style={{ color: this.props.theme.fontColor.inverted }}>
              {tooltip.toUpperCase()}
            </Small>
          </ReactTooltip>
        )}
      </span>
    );
  }
};

export default withMediaQuery(withTheme(ButtonIcon));
