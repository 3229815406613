import React from 'react';
import styled from 'styled-components';

export const Text = styled.p`
  color: ${(props) => {
    switch (props.type) {
      default:
      case 'primary':
        return props.theme.fontColor.primary;
      case 'secondary':
        return props.theme.fontColor.secondary;
      case 'hint':
        return props.theme.fontColor.hint;
      case 'error':
        return props.theme.fontColor.error;
      case 'link':
        return props.theme.fontColor.link;
    }
  }};
  display: ${(props) => (props.inline ? 'inline' : 'block')};
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.text.fontSize};
  line-height: 24px;
  margin: auto;
`;

export const ErrorText = (props) => <Text type="error" {...props} />;
