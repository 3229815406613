import React from 'react';
import styled from 'styled-components';
import { defaultTheme } from './ThemeProvider';

// Spinner is based off examples from here: https://loading.io/css/

export const SpinnerBase = styled.div`
  display: inline-block;
  position: relative;
  width: ${({ size }) => `${size || 40}px`};
  height: ${({ size }) => `${size || 40}px`};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => `${size ? size * 0.85 : 34}px`};
    height: ${({ size }) => `${size ? size * 0.85 : 34}px`};
    margin: 0px;
    border: ${(props) => `4px solid ${props.color}`};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => `${props.color} transparent transparent transparent`};
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SpinnerIcon = (props) => (
  <SpinnerBase {...props}>
    <div />
    <div />
    <div />
    <div />
  </SpinnerBase>
);

export default ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: defaultTheme.padding.sm,
      }}
    >
      <SpinnerIcon color={defaultTheme.uiColor.success} />
      <span style={{ paddingLeft: defaultTheme.padding.sm }}>{children}</span>
    </div>
  );
};
