import styled from 'styled-components';

export default styled.label`
  color: ${(props) =>
    props.hasError ? props.theme.fontColor.error : props.theme.fontColor.secondary};
  display: block;
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.label.fontSize};
  letter-spacing: 1.5px;
  line-height: 21px;
  text-transform: uppercase;
  display: ${(props) => (props.inline ? 'inline' : 'block')};
`;
