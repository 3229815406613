/**
 * Wrapper for react-select
 * https://github.com/JedWatson/react-select/
 */
import React from 'react';
import ReactSelect from 'react-select';
import { withTheme } from 'styled-components';
import { hexToRgbA } from './helpers';

// Reference of the default styles:
// https://github.com/JedWatson/react-select/blob/v2/src/styles.js
const selectStyles = {
  control: (base, state) => {
    let borderColor = state.selectProps.theme.uiColor.outline;
    if (state.selectProps.hasError) {
      borderColor = state.selectProps.theme.uiColor.error;
    } else if (state.isFocused) {
      borderColor = state.selectProps.theme.uiColor.secondary;
    }
    return {
      ...base,
      boxShadow: 'none',
      borderWidth: state.isDisabled ? 0 : 1,
      borderStyle: 'solid',
      borderColor,
      backgroundColor: state.isDisabled
        ? hexToRgbA(state.selectProps.theme.uiColor.outline, 0.2)
        : state.selectProps.theme.uiColor.neutral,
      fontFamily: 'Heebo',
      fontWeight: 'normal',
      fontSize: 16,
      // Hack since we cannot set the border color alpha correctly and
      // need to overcompensate for the height to remain the same.
      ...(state.isDisabled ? { paddingTop: '1px', paddingBottom: '1px' } : {}),
      // Remove default hover style
      '&:hover': {},
      '&:focus': {
        borderColor,
      },
    };
  },
  menu: (base, state) => {
    let borderColor = state.selectProps.theme.uiColor.secondary;
    if (state.selectProps.hasError) {
      borderColor = state.selectProps.theme.uiColor.error;
    }
    return {
      ...base,
      borderRadius: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor,
      fontFamily: 'Heebo',
      fontWeight: 'normal',
      fontSize: 16,
      zIndex: 2,
      '&:hover': {
        borderColor,
      },
    };
  },
  dropdownIndicator: (base, state) => {
    let color = state.selectProps.theme.fontColor.hint;
    if (state.isDisabled) {
      color = state.selectProps.theme.fontColor.hint;
    } else if (state.selectProps.hasError) {
      color = state.selectProps.theme.fontColor.error;
    }
    return {
      ...base,
      color,
    };
  },
  // Hide/disable indicator separator bar
  indicatorSeparator: () => ({}),
  input: (base, state) => {
    return {
      ...base,
      margin: 0,
      padding: '10px 12px',
      fontSize: '16px',
      letterSpacing: '0.1px',
      lineHeight: '24px',
      color: state.theme.fontColor.primary,
    };
  },
  placeholder: (base, state) => ({
    ...base,
    margin: 0,
    padding: '10px 12px',
    fontSize: '16px',
    letterSpacing: '0.1px',
    lineHeight: '24px',
    color: state.selectProps.theme.fontColor.hint,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  singleValue: (base) => ({
    ...base,
    marginLeft: 12,
  }),
};

const ReactSelectWrapper = ({ style, ...props }) => (
  <div style={style}>
    <ReactSelect styles={selectStyles} {...props} />
  </div>
);
ReactSelectWrapper.displayName = 'ReactSelectWrapper';

export default withTheme(ReactSelectWrapper);
