import React, { Component } from 'react';
import { Checkbox, Card, Box, Button, Text } from './index';
import { withTheme } from 'styled-components';

class Menu extends Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.body.addEventListener('mousedown', this.onClickedBody);
  }

  componentWillUnmount() {
    document.body.removeEventListener('mousedown', this.onClickedBody);
  }

  onClickedBody = (event) => {
    // Hide menu if we click outside of it
    if (
      !this.menuButtonNode ||
      this.menuButtonNode === event.target ||
      this.menuButtonNode.contains(event.target)
    )
      return;
    if (
      !this.dropdownNode ||
      this.dropdownNode === event.target ||
      this.dropdownNode.contains(event.target)
    )
      return;
    this.setState({ isOpen: false });
  };

  handleToggleDropdown = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const { children, theme, icon } = this.props;
    const { isOpen } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <Card
          boxShadow
          style={{
            padding: 2,
            cursor: 'pointer',
            position: 'absolute',
            display: 'flex',
            right: 0,
            fontSize: 'initial',
            lineHeight: 'initial',
          }}
          onClick={this.handleToggleDropdown}
          ref={(node) => {
            this.menuButtonNode = node;
          }}
        >
          <Button secondary link style={{ padding: 0, lineHeight: 0 }}>
            {icon}
          </Button>
        </Card>
        {isOpen && (
          <Card
            boxShadow
            style={{ position: 'absolute', padding: 16, top: 30, right: 0 }}
            ref={(node) => {
              this.dropdownNode = node;
            }}
          >
            {children}
          </Card>
        )}
      </div>
    );
  }
}

export const MenuItem = ({ children }) => <Box padding="xxs">{children}</Box>;

export const MenuHeader = ({ children }) => (
  <MenuItem>
    <Box>
      <Text style={{ fontSize: 14, letterSpacing: 1.4 }}>{children}</Text>
    </Box>
  </MenuItem>
);

export const MenuCheckbox = withTheme(({ theme, name, checked, onChange, children }) => (
  <div style={{ whiteSpace: 'nowrap' }}>
    <Checkbox name={name} checked={checked} onChange={onChange}>
      <span
        style={{
          fontSize: 12,
          color: theme.uiColor.secondary,
          marginLeft: 3,
          marginTop: 4,
          letterSpacing: 1.5,
        }}
      >
        {children}
      </span>
    </Checkbox>
  </div>
));

export default withTheme(Menu);
