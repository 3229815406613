import React from 'react';
import styled from 'styled-components';
import { SpinnerIcon } from './Spinner';
import { defaultTheme } from './ThemeProvider';
import { hexToRgbA } from './helpers';

const isPrimary = ({ primary, secondary }) => primary !== undefined || (!primary && !secondary);

const Button = styled.button`
  background-color: ${(props) => {
    if (isPrimary(props)) {
      return props.disabled
        ? hexToRgbA(props.theme.uiColor.secondary, 0.4)
        : props.theme.uiColor.secondary;
    }
    return props.theme.uiColor.neutral;
  }};
  border-color: ${(props) =>
    isPrimary(props) ? '' : hexToRgbA(props.theme.uiColor.secondary, props.disabled ? 0.4 : 1)};
  border-radius: ${(props) => props.theme.borderRadius};
  border-style: ${(props) => (isPrimary(props) ? 'none' : 'solid')};
  border-width: ${(props) => (props.link ? '0px' : '1px')};
  box-sizing: border-box;
  color: ${(props) =>
    isPrimary(props)
      ? hexToRgbA(props.theme.uiColor.neutral, props.disabled ? 0.8 : 1)
      : hexToRgbA(props.theme.uiColor.secondary, props.disabled ? 0.4 : 1)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: ${(props) => (props.inline ? 'inline' : 'inline-block')};
  font-family: ${(props) => props.theme.fontFamily.default};
  font-size: ${(props) => props.theme.button.fontSize};
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 21px;
  outline: 0;
  padding: ${(props) => (props.link ? null : '11px 32px 8px 31px')};
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  &:hover {
    color: ${(props) => {
      if (!props.link) {
        return null;
      }
      if (isPrimary(props)) {
        return props.disabled
          ? hexToRgbA(props.theme.uiColor.secondary, 0.4)
          : hexToRgbA(props.theme.uiColor.secondary, 0.8);
      }
      return props.disabled
        ? props.theme.uiColor.inverted
        : hexToRgbA(props.theme.uiColor.secondary, 0.1);
    }}
    background-color: ${(props) => {
      if (props.link) {
        return null;
      }
      if (isPrimary(props)) {
        return props.disabled
          ? hexToRgbA(props.theme.uiColor.secondary, 0.4)
          : hexToRgbA(props.theme.uiColor.secondary, 0.8);
      }
      return props.disabled
        ? props.theme.uiColor.neutral
        : hexToRgbA(props.theme.uiColor.secondary, 0.1);
    }};
  }
`;
const Spin = ({ secondary }) => (
  <span style={{ position: 'absolute', left: 10, top: 14 }}>
    <SpinnerIcon color={secondary ? defaultTheme.uiColor.secondary : 'white'} size={16} />
  </span>
);

const ButtonEnhanced = ({ spinner, children, ...props }) => {
  if (spinner) {
    return (
      <div style={{ position: 'relative' }}>
        <Spin {...props} />
        <Button {...props}>{children}</Button>
      </div>
    );
  }
  return <Button {...props}>{children}</Button>;
};

export default ButtonEnhanced;
