import React from 'react';
import ReactDOM from 'react-dom';

// @NOTE
// There is a bug in Chrome that surfaces in React when calling
// `window.print` programmatically.
// Basically:
// Running the development version of the app will trigger the error
// but in a production build the problem doesn't occur.
// It's possible to test printing in Safari with success.
// https://github.com/facebook/react/issues/16734
// Otherwise, in Chrome just press Cmd + P to see the print preview.

class Printable extends React.Component {
  state = {
    isReady: false,
  };
  componentWillMount() {
    // Create printable container
    const id = 'airbusutm-printable';
    const printEl = document.querySelector(`#${id}`);
    if (!printEl) {
      this.printEl = document.createElement('div');
      this.printEl.id = id;
      document.body.appendChild(this.printEl);

      // Create stylesheet
      this.style = document.createElement('style');
      this.style.id = `${id}-style`;
      document.head.appendChild(this.style);

      let rootId = this.props.rootId || undefined;
      if (!rootId) {
        if (document.querySelector('#root')) {
          rootId = 'root';
        } else if (document.querySelector('body div')) {
          rootId = document.querySelector('body div').id;
        } else {
          throw new Error('Unknown root element');
        }
      }

      // @NOTE
      // The reason we are using visibility hidden/visible is because using
      // `display` `none`/`block` does not the set the offsetHeight which
      // is needed by mapbox when sizing the map on first load.
      const rules = [
        `#${id} { visibility: hidden; position: absolute; top: -10000px; }`,
        `@media print  { #${rootId}  { display: none;  } #${id} { visibility: visible; position: relative; top: 0; } }`,
      ];
      rules.map((rule, index) => this.style.sheet.insertRule(rule, index));
      this.renderContent();
    }
  }
  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(this.printEl);
    document.head.removeChild(this.style);
    document.body.removeChild(this.printEl);
  }
  renderContent() {
    const { Content } = this.props;
    ReactDOM.render(<Content onReady={() => this.setState({ isReady: true })} />, this.printEl);
  }
  render() {
    const { children } = this.props;
    const { isReady } = this.state;
    return children({ print: () => window.print(), isReady });
  }
}

export default Printable;
